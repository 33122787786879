<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title align-items-center">
        <h3 class="card-label font-weight-bolder mb-0">{{ $t("Employees") }}</h3>
      </div>
    </div>
    <div class="card-body detail">
      <b-container>
        <b-row>
          <b-col class="px-0">
            <SecondmentEmployeeAvailabilityTable ref="availabilityTable" v-model="selectedEmployees" :read-only="true"
              :show-toggle="false" :date-from="dateFrom" :date-to="dateTo" selected-first :clients="secondment.clients"
              :documents-data="documents" :secondment-employees="secondmentEmployees" :selected-service-comp="service"
              :selected-country="secondment.country" :secondment-id="secondment.id" :disable-edit-gant="true"
              @add-contract="$router.push({ name: 'associate-contract', params: { ppId: $event } })">
            </SecondmentEmployeeAvailabilityTable>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SecondmentEmployeeAvailabilityTable from "@/view/components/tables/SecondmentEmployeeAvailabilityTable.vue";
export default {
  components: {
    SecondmentEmployeeAvailabilityTable,
  },

  props: {
    secondment: {
      type: Object,
      required: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    resetToggle: {
      type: Boolean,
      default: false,
    },
    service: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      secondmentEmployees: [],
      selectedEmployees: [],
      documents: {},
      gantt: {
        secondmentEmployee: null,
      },
    };
  },

  computed: {
    dateFrom() {
      return this.secondment.date_start;
    },

    dateTo() {
      return this.secondment.date_end;
    },

    country() {
      return this.secondment.country;
    },
  },

  watch: {
    resetToggle(val) {
      if (!val) {
        this.resetAllEmployees();
      }
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Assignments") },
      { title: this.$t("Employees") },
    ]);
    this.selectedEmployees = this.secondment.employees;
    this.secondmentEmployees = this.secondment.employees;
  },

};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}

.employees-list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  gap: 2rem;
}

::v-deep .asd__month .asd__week .asd__day--secondment {
  background-color: #ffd27d;
}
</style>
