var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-center"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Employees")))])])]), _c('div', {
    staticClass: "card-body detail"
  }, [_c('b-container', [_c('b-row', [_c('b-col', {
    staticClass: "px-0"
  }, [_c('SecondmentEmployeeAvailabilityTable', {
    ref: "availabilityTable",
    attrs: {
      "read-only": true,
      "show-toggle": false,
      "date-from": _vm.dateFrom,
      "date-to": _vm.dateTo,
      "selected-first": "",
      "clients": _vm.secondment.clients,
      "documents-data": _vm.documents,
      "secondment-employees": _vm.secondmentEmployees,
      "selected-service-comp": _vm.service,
      "selected-country": _vm.secondment.country,
      "secondment-id": _vm.secondment.id,
      "disable-edit-gant": true
    },
    on: {
      "add-contract": function addContract($event) {
        return _vm.$router.push({
          name: 'associate-contract',
          params: {
            ppId: $event
          }
        });
      }
    },
    model: {
      value: _vm.selectedEmployees,
      callback: function callback($$v) {
        _vm.selectedEmployees = $$v;
      },
      expression: "selectedEmployees"
    }
  })], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }